<template>
  <div class="layout-top-nav">
    <icon-svg></icon-svg>
    <div class="wrapper">
      <!--Navigation Bar-->
      <navbar></navbar>

      <!--Content wrapper -->
      <Body></Body>

      <!--Footer-->
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.min.css";
Vue.use(BootstrapVue);

import Navbar from "@/components/staff/panel/Navbar";
import Footer from "@/components/staff/panel/Footer";
import Body from "@/components/staff/panel/Body";
import IconSvg from "@/components/widget/IconSvg";

export default {
  name: "panel",
  components: {
    IconSvg,
    Footer,
    Navbar,
    Body,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
@import url("../assets/lte/adminlte.min.css");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.wrapper {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  background: #f4f6f9;
}
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .content-wrapper,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .main-footer,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .main-header {
  margin-left: 0 !important;
}

.card-primary.card-outline {
  border-top: 3px solid #46ccff !important;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.page-item.active .page-link {
  background-color: #00aae5 !important;
  border-color: #00aae5 !important;
}
.page-link :not(.active) {
  color: #00aae5 !important;
}
</style>
