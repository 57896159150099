<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ get_all._breadcrumb.title }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">
              <router-link to="/agent">Chat</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/panel">Panel</router-link>
            </li>
            <li class="breadcrumb-item active">
              {{ get_all._breadcrumb.path }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- /.content-header -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Breadcrumb",
  computed: {
    ...mapGetters(["get_all"]),
  },
};
</script>

<style scoped></style>
