<template>
  <!-- Main Footer -->
  <footer class="main-footer">
    <!-- To the right -->
    <div class="float-right d-none d-sm-inline">
      Rumahweb Messenger v{{ version }}
    </div>
    <!-- Default to the left -->
    <strong>Copyright &copy; 2021</strong>
  </footer>
</template>

<script>
import { version } from "@/common/general.config";

export default {
  name: "Footer",
  data() {
    return {
      version: version,
    };
  },
};
</script>
