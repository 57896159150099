<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <breadcrumb></breadcrumb>

    <!-- Main content -->
    <div class="content">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/staff/panel/Breadcrumb";
export default {
  name: "Body",
  components: {
    Breadcrumb,
  },
};
</script>
