<template>
  <nav class="main-header navbar navbar-expand-md navbar-light navbar-white">
    <div class="container">
      <div class="collapse navbar-collapse order-3" id="navbarCollapse">
        <ul class="navbar-nav">
          <li>
            <router-link
              to="/panel"
              class="nav-link"
              :class="checkActive('/panel')"
              ><i class="fa fa-home"></i> Home</router-link
            >
          </li>
          <li>
            <router-link
              to="/panel/agent"
              class="nav-link"
              :class="checkActive('/panel/agent')"
              ><i class="fa fa-users"></i> Agent</router-link
            >
          </li>
          <li>
            <router-link
              to="/panel/chat"
              class="nav-link"
              :class="checkActive('/panel/chat')"
              ><i class="fa fa-comments"></i> Active Chat</router-link
            >
          </li>
          <li>
            <router-link
              to="/panel/history"
              class="nav-link"
              :class="checkActive('/panel/history')"
              ><i class="fa fa-history"></i> History</router-link
            >
          </li>
          <li>
            <router-link
              to="/panel/rating"
              class="nav-link"
              :class="checkActive('/panel/rating')"
              ><i class="fa fa-star-o"></i> My Rating</router-link
            >
          </li>
          <li>
            <router-link
              to="/panel/shortcut"
              class="nav-link"
              :class="checkActive('/panel/shortcut')"
              ><i class="fa fa-share-square-o"></i> Shortcut</router-link
            >
          </li>
        </ul>
      </div>

      <!-- Right navbar links -->
      <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
        <li class="btn btn-warning btn-sm mr-1">
          <router-link to="/agent" class="text-white"
            ><i class="fa fa-arrow-left"></i> Chat Area</router-link
          >
        </li>
        <li class="btn btn-sm" :class="status ? 'btn-success' : 'btn-warning'">
          <a>You are {{ status ? "Online" : "Away" }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { staffCookies } from "@/common/mixins/mix_cookies";

export default {
  name: "Navbar",
  mixins: [staffCookies],
  data() {
    return {
      status: false,
    };
  },
  methods: {
    checkActive: function (menu) {
      if (menu === this.get_all._activeMenu) {
        return "active";
      }
    },
  },
  computed: {
    ...mapGetters(["get_all"]),
  },
  mounted() {
    let cookies = this.cookies.get();
    if (typeof cookies.isOnline !== "undefined") {
      this.status = cookies.isOnline;
    }
  },
};
</script>

<style lang="scss" scoped>
nav {
  //background: #46ccff;
  background: #00aae5;
  a.nav-link {
    color: white !important;
  }
  ul {
    .active {
      color: black !important;
    }
  }
}
</style>
